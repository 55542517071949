.remove-body-padding {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-tabs-nav-list {
    margin: 12px 24px 0px 24px;
  }
}

