@primary-color: #fab115; // primary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 16px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@btn-primary-color: #3c3d3c;
@btn-font-weight: 600;
@border-radius-base: 4px;
@link-color: @primary-color;

@btn-text-shadow: none;
@menu-item-active-border-width: 10px;

// Set screen width breakpoints to match tailwind
@screen-sm: 576px;
@screen-md: 768px;
@screen-lg: 1024px;
@screen-xl: 1280px;
@screen-xxl: 1536px;

.anticon svg {
  display: block;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: 4px solid @primary-color;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  height: 4px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}
