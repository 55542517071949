.container {
  padding: 10px 16px;
}

.imageContainer {
  padding: 6rem 4rem;
}

.alertContainer {
  padding: 10px 16px;
}

