.custom-header {
  .ant-collapse-header {
    padding: 0 !important;
    display: block !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

#propertyType {
  display: flex;
  flex-direction: column;
}

