/* ./src/index.css */
@tailwind base;

@layer base {
  /* override preflight base styling */
  html {
    font-family: "Lato", system-ui, sans-serif;
  }

  h1 {
    @apply text-4xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  img {
    display: inline;
  }
}

@tailwind components;

@layer components {
  .secondary {
    background-color: theme("colors.blue.DEFAULT") !important;
  }

  .secondary:hover {
    background-color: theme("colors.blue.dark") !important;
  }

  .tertiary {
    background-color: theme("colors.gray.400") !important;
  }

  .tertiary:hover {
    background-color: theme("colors.gray.500") !important;
  }

  .btn-tertiary {
    @apply tertiary hover:bg-gray-500;
  }

  .panel {
    @apply px-8 py-6 bg-white border rounded-lg;
  }

  .panel-w-header {
    @apply p-4 bg-white rounded-b-lg;
  }

  .panel-header {
    @apply px-8 py-4 bg-gray-200 rounded-t-lg;
  }

  .page-title {
    @apply mt-6 mb-8;
  }
}

@tailwind utilities;

@layer utilities {
  .max-w-btn {
    max-width: 180px;
  }
}
