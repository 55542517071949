.viewContainer {
  display: flex;
  flex: 1;
  max-width: 380px;
  flex-direction: column;
  padding: 10px 16px;
  .form {
    flex: 1;
  }
  align-self: center;
}

.controlContainer {
  display: flex;
  flex: 1;
  // margin-top: 40px;
}

