// Antd overrides
.spaced-collapse {
  border: none;
  background-color: transparent;

  .ant-collapse-item {
    background-color: white;
    border-radius: 8px !important;
    margin-bottom: 16px;
  }

  .ant-collapse-content {
    border-radius: 0px 0px 8px 8px !important;
  }
}

