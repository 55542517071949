@import "../../theme/agents-corner-theme";

.stepper-container {
  max-width: 305px;
  width: 100%;
  margin-top: 10vh;
}

.stepper-progress {
  .ant-progress-bg {
    background-color: @primary-color;
  }
  .ant-progress-inner {
    background-color: @border-color-base;
  }
}

.stepper-title {
  text-align: center;
}

