.ant-table-row-expand-icon-cell {
  display: none;
}

.ant-table-selection-column {
  visibility: hidden;
}

// Selected contact border
.ant-table-tbody > tr.ant-table-row-selected > td:nth-child(2) {
  border-left: 4px solid #3688c8;
}

// Selected row background color
.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #f3f9ff;
}

// Selected row hover color
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: lightgray;
}

.hide-selection {
  .ant-table-selection-col {
    width: 0;
  }
}

.transparent-headers {
  .ant-table-thead > tr > th {
    overflow-wrap: normal;
    background: transparent;
  }
}

