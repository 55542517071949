.ant-carousel .slick-dots-bottom {
  bottom: 0;
}

.ant-carousel .slick-dots {
  bottom: inherit;
}

.ant-carousel .slick-dots li button {
  background: black;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: black;
}

