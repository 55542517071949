.layout {
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.layout-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 800px;
  width: 100%;
}

