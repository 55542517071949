.advanced-options {
  .ant-row {
    display: block;
  }

  .ant-collapse-header {
    padding-left: 20px !important;
  }
  .ant-collapse-arrow {
    left: 0px !important;
  }
  .ant-collapse-content-box {
    padding-left: 0;
  }
}

.remove-antd-optional-mark {
  // prevent original `ant-form-item-optional` class from displaying
  // (typically preceded by span (marks/tooltips) or div (label col))
  // so we can set custom placement of the optional mark
  .ant-form-item-label > label span ~ .ant-form-item-optional,
  .ant-form-item-label > label div ~ .ant-form-item-optional {
    display: none;
  }
}


